<div class="container py-5" *ngIf="!standalone; else onlyH2">
    <div class="row justify-content-center justify-content-sm-start">
        <div class="col-11 col-sm-12 col-md-10">
            <ng-container *ngTemplateOutlet="onlyH2"></ng-container>
        </div>
    </div>
</div>

<ng-template #onlyH2>
    <h2>
        <ng-content></ng-content>
    </h2>
</ng-template>
