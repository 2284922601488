<bott-home-header [config]="{
    images: [
        '/assets/images/slider/melsungen.jpg',
        '/assets/images/slider/felsberg.jpg',
        '/assets/images/slider/lohfelden.jpg'
    ],
    heading: 'Verkaufe deine <br>Immobilie stressfrei<br>und komfortabel mit uns!',
    cta: {
        text: 'Wieviel ist deine Immobilie wert?',
        link: {
            text: 'Starte Wertermittlung',
            href: './wertermittlung'
        }
    }
}"></bott-home-header>

<bott-statement>
    Als Immobilienmakler unterstützen wir dich mit Professionalität und Engagement beim Verkauf oder Kauf von Immobilien im Landkreis Kassel sowie im Schwalm-Eder-Kreis.
</bott-statement>

<bott-statement>
    Welche <span class="highlight">Vorteile</span> bieten wir beim <span class="highlight">Verkauf</span> deiner Immobilie?
</bott-statement>

<bott-icon-heading-text [config]="{
    mode: 'small',
    items: [
        {
            icon: 'trending_up',
            heading: 'Mehr Geld für deine Immobilie',
            text: 'Unsere Mission ist es den bestmöglichen Preis für deine Immobilie zu erzielen. Dies gelingt uns durch unsere innovative und professionelle Vermarktung in der wir deine Immobilie von der besten Seite zeigen.'
        },
        {
            icon: 'more_time',
            heading: 'Mehr Zeit für die wichtigen Dinge',
            text: 'Der Immobilienverkauf ist ein anstrengender Vollzeitjob der sich über viele Wochen ziehen kann. Lass uns Profis das für dich übernehmen und genieße deine wertvolle Zeit lieber mit Freunden, Familie oder Hobbys.'
        },
        {
            icon: 'emoji_events',
            heading: 'Schneller das Ziel erreichen',
            text: 'Wir geben Vollgas bei der Vermarktung deiner Immobilie und haben eine überdurchschnittlich schnelle Vermarktungszeit. Somit kannst du schneller einen Haken an den Verkauf machen und dich auf andere Dinge konzentrieren.'
        },
        {
            icon: 'admin_panel_settings',
            heading: 'Auf der sicheren Seite sein',
            text: 'Durch unsere Expertise, Erfahrung und Kontakte kannst du dir sicher sein, dass alles nach Plan verläuft und es rechtlich sowie organisatorisch keine Überraschungen gibt.'
        },
        {
            icon: 'insert_emoticon',
            heading: 'Maximaler Erfolg und Zufriedenheit',
            text: 'Jede Immobilie behandeln wir, als wäre sie unsere eigene und steigern durch unsere Motivation und Engagement den Erfolg im gesamten Prozess. Wir sind erst zufrieden, wenn du es bist.'
        },
        {
            icon: 'fingerprint',
            heading: 'Individuelle persönliche Betreuung',
            text: 'Du stehst bei uns im Mittelpunkt und wir gehen präzise auf deine individuelle Situation und Vorstellung ein und setzen alles daran, dich rundum glücklich zu machen.'
        }
    ]
}"></bott-icon-heading-text>

<bott-image-and-text-box [config]="{
        image: {
            url: '/assets/images/home/felsberg.jpg',
            alt: 'Immobilienmakler aus Felsberg'
        }
    }">
    Wir sind ein modernes, familiengeführtes und regionales <b>Immobilienmakler</b> Unternehmen und kennen den lokalen Markt im
    <b>Schwalm-Eder-Kreis</b>, <b>Kassel</b> und
    <b>Landkreis-Kassel</b> wie unsere Westentasche.
</bott-image-and-text-box>

<bott-statement>
    Wie genau <span class="highlight">erreichen</span> wir diese <span class="highlight">Ziele</span> für dich?
</bott-statement>

<bott-icon-heading-text [config]="{
    mode: 'small',
    items: [
        {
            icon: '360',
            heading: 'Transparenter Rundum-Komplettservice',
            text: 'Wir kümmern uns einfach um alles was anfällt und du kannst dich entspannt zurück lehnen und dich auf den erfolgreichen Verkaufsabschluss freuen. Du bekommst von uns alle Infos und wirst stets mit einbezogen.'
        },
        {
            icon: 'analytics',
            heading: 'Fundierte Wertermittlung und Marktanalyse',
            text: 'Wir ermitteln den marktgerechten Preis deiner Immobilie indem wir eine detaillierte Berechnung nach den normierten Wertermittlungsverfahren durchführen und dazu eine spezifische Wohnmarktanalyse anfertigen.'
        },
        {
            icon: 'camera_alt',
            heading: 'Professionelle Fotos und Videos',
            text: 'Wir sind Experten im Umgang mit der Kamera und verstehen es deine Immobilie gekonnt zu präsentieren. Dabei entstehen eindrucksvolle und ansprechende Aufnahmen die sich von der Masse abheben und überzeugen.'
        },
        {
            icon: '3d_rotation',
            heading: 'Neue innovative Technologien',
            text: 'Wir nutzen 360° Kameras und Drohnen für eine außergewöhnliche Wahrnehmung deiner Immobilie. Dabei entstehen fesselnde virtuelle 360° Rundgänge und beeindruckende Luftaufnahmen.'
        },
        {
            icon: 'local_florist',
            heading: 'Home Staging das überzeugt',
            text: 'Um deiner Immobilie noch den letzten Schliff zu verpassen, setzen wir auf Home Staging und optimieren so das Erscheinungsbild je nach Bedarf und erhöhen dadurch das Interesse und den erreichbaren Preis.'
        },
        {
            icon: 'collections',
            heading: 'Hochwertiges Exposé',
            text: 'Die professionelle und informative Darstellung deiner Immobilie ist wichtig um einen überzeugenden und hochwertigen ersten Eindruck zu hinterlassen. Aufgearbeitete 3D Grundrisse und professionelle Objektbilder sind Pflicht.'
        },
        {
            icon: 'campaign',
            heading: 'Individuelles Vermarktungskonzept',
            text: 'Immobilienportale, Social Media oder doch die klassischen Printmedien? Wir erarbeiten ein individuell auf deine Immobilie zugeschnittenes Konzept um die passende Zielgruppe bestmöglich anzusprechen.'
        },
        {
            icon: 'connect_without_contact',
            heading: 'Kompetente Beratung',
            text: 'Wir beraten dich ausführlich in allen Bereichen rund um den Immobilienverkauf und geben dir genau die Antwort, die du brauchst. Bei Bedarf greifen wir auf unsere Partner wie z.B. Handwerker, Entrümpler, Finanzierungsberater, Notare usw. zurück.'
        }
    ]
}"></bott-icon-heading-text>

<div class="pb-5">
    <bott-contact-options [mode]="'small'"></bott-contact-options>
</div>

<div class="bg-alt my-5 py-5" id="prozess">

    <bott-statement>
        Mit uns als Immobilienmakler hast du einen starken Partner an deiner Seite. Wir übernehmen alle Aufgaben des Immobilienberkaufs und holen das Optimum für dich heraus.
    </bott-statement>

    <bott-high-level-process [config]="{
        step1: 'Wir ermitteln den marktgerechten Wert deiner Immobilie, indem wir die geeigneten Wertermittlungsverfahren mit unseren Marktkenntnissen kombinieren, um für dich im Anschluss den bestmöglichen Preis zu erzielen.
        ',
        step2: 'Hochwertige Bilder und interessante Beschreibungen zeichnen unsere erfolgreichen Exposés aus. Hierbei nutzen wir moderne Techniken, setzen deine Immobilie verkaufsfördernd in Szene und verstärken dadurch das Interesse der Zielgruppe.
        ',
        step3: 'Mit einer individuellen Vermarktungsstrategie sprechen wir gezielt Interessenten an, filtern die passenden heraus und führen souverän überzeugende Besichtigungen durch.
        ',
        step4: 'Von der strategischen Kaufpreisverhandlung über die Vertragsunterzeichnung beim Notar bis zur Schlüsselübergabe und darüber hinaus: Wir stehen dir als erfahrener Partner stets zur Seite.
        '
    }"></bott-high-level-process>

</div>

<bott-contact-options></bott-contact-options>

<bott-statement>
    Du hast offene Fragen? Lies nachfolgend unsere “Häufig gestellten Fragen” oder kontaktiere uns
    persönlich. Wir helfen dir gerne weiter.
</bott-statement>

<bott-questions [entries]="[
    {
        question: 'Welche Kosten entstehen für mich als Verkäufer?',
        shortAnswer: [
            'Wir setzen auf eine Vielzahl von hochwertigen Vermarktungsaktivitäten wie z.B. einen professionellen Immobilienfotografen, virtuelle 3D Rundgänge, Luftaufnahmen, Vermarktung in den Immobilienportalen und gezielt über die Sozialen Medien.
            Damit ist sichergestellt, dass wir alles Mögliche für dich und deine Immobilie tun, um so einen möglichst hohen Preis zu erzielen. Um diese Aktivitäten umzusetzen benötigen wir ein entsprechend hohes Werbebudget. Dies trägt aber maßgeblich dazu bei, dass wir höhere Preise erzielen können und unterm Strich für eine größere Zufriedenheit unserer Kunden sorgen. Wir arbeiten mit einer einseitigen Provision für den Verkäufer in Höhe von 6% inkl. MwSt. und stellen damit sicher, die Interessen des Eigentümers optimal vertreten zu können.'
        ]
    },
    {
        question: 'Welche Unterlagen brauche ich für den Verkauf meiner Immobilie?',
        shortAnswer: [
            'Unter anderem werden der Energieausweis, Grundbuchauszug, Lageplan und die Baubeschreibung benötigt. Wir kümmern uns um alles und erstellen oder besorgen sämtliche Dokumente, die für den Notar, die Interessenten und für die möglicherweise finanzierende Bank benötigt werden.'
        ]
    },
    {
        question: 'Wieviel ist meine Immobilie wert?',
        shortAnswer: [
            'Aus der Entfernung ist das schwer zu sagen. Wir ermitteln den Wert deiner Immobilie nach einer detaillierten Begehung individuell nach den normierten Wertermittlungsverfahren und kombinieren dies mit der aktuellen Situation am Immobilienmarkt.'
        ]
    },
    {
        question: 'Warum mit einem Immobilienmakler verkaufen?',
        shortAnswer: [
            'Menschen, die bereits eine Immobilie ohne Makler verkauft haben wissen es: Man hat einen enormen Zeitaufwand, muss sich um eine Vielzahl von unterschiedlichen Dokumenten kümmern, man kennt den Immobilienmarkt nicht und ist sich unsicher hinsichtlich Rechtsfragen.<br><br>Als Immobilienmakler sind wir Experten für den Verkauf von Immobilien. Mit uns musst du deine wertvolle Zeit nicht in die komplexe Vermarktung deiner Immobilie stecken, sondern profitierst von unseren Erfahrungen und der Routine, mit der wir alle nötigen Aufgaben durchführen. Dabei stellen wir sicher, dass alle Schritte professionell durchgeführt werden und dadurch das beste Ergebnis erzielt wird. Wir sparen dir Zeit, schützen vor rechtlichen Problemen, organisieren und erstellen alle benötigten Unterlagen, kümmern uns um eine Masse an potentiellen Interessenten, setzen deine Immobilie gekonnt in Szene und erzielen dadurch einen höheren Preis für dich.'
        ]
    },
    {
        question: 'An welchen Orten sind wir tätig?',
        shortAnswer: [
            'Unsere Leistungen bieten wir vor allem in den folgenden Einzugsgebieten an: Kassel, Baunatal, Fritzlar,
            Melsungen, Lohfelden, Felsberg, Gudensberg, Edermünde, Guxhagen, Fuldabrück, Malsfeld,
            Homberg Efze, Vellmar, Frielendorf, Spangenberg, Borken, Söhrewald,
            Wabern, Kaufungen, Niestetal.
            <br><br>
            Selbstverständlich freuen wir uns auch auf deine Anfrage über unser Stammgebiet hinaus. Am besten klären
            wir deine Anforderungen im persönlichen Gespräch. Wir freuen uns auf deine Anfrage.'        
        ]
    }
]"></bott-questions>
