<div [ngClass]="'container ' + config.containerClass">
    <div class="row justify-content-center justify-content-sm-start">
        <div class="mb-md-4 item" [ngClass]="{
            'col-11 col-md-12' : config.columnsOnMobile === 1,
            'col-5 col-md-6' : config.columnsOnMobile === 2,
            'col-sm-6 col-lg-4': config.columns === 3,
            'col-md-6 col-lg-3': config.columns === 4,
            'mb-5 mb-md-4': !isLast,
            'small': config?.mode === 'small',
            'text-center': config?.mode === 'regular'
        }" *ngFor="let item of config?.items; index as idx; last as isLast">
            <div class="icon-wrapper">
                <i class="material-icons color-yellow">{{item?.icon}}</i>
            </div>
            <h3 [innerHTML]="item?.heading"></h3>
            <p class="d-md-block" [class.d-none]="item.readMoreText && !showMoreIndices.includes(idx)" [innerHTML]="item?.text + (item?.readMoreText ? ' ' + item?.readMoreText : '')"></p>
            <ng-container *ngIf="item?.readMoreText">
                <p [class.d-none]="showMoreIndices.includes(idx)" class="d-md-none" [innerHTML]="item?.text"></p>
                <span role="button" class="color-yellow d-md-none" *ngIf="!showMoreIndices.includes(idx)" (click)="showMoreIndices.push(idx)">Mehr erfahren</span>
            </ng-container>
        </div>
    </div>
</div>
