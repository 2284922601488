import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NgxCookieBannerModule } from 'ngx-cookie-banner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { NavigationModule } from './navigation/navigation.module';
import { PrismicModule } from './prismic/prismic.module';
import { SentryErrorHandler } from './sentry';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ScullyLibModule,
    NavigationModule,
    HomeModule,
    NgxCookieBannerModule.forRoot({
      cookieName: 'bott-immobilien-cookie-consent',
    }),
    PrismicModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
