import { Component, OnInit, Input } from '@angular/core';

export interface CtaCardContent {
  title: string;
  text: string;
  link: {
    href: string;
    text: string;
  },
  icon: 'phone' | 'mail' | 'whatsapp';
}

export type CtaCardMode = 'regular' | 'small';

@Component({
  selector: 'bott-cta-card',
  templateUrl: './cta-card.component.html',
  styleUrls: ['./cta-card.component.scss']
})
export class CtaCardComponent implements OnInit {

  @Input()
  containerClass: string;

  @Input()
  preset: 'phone' | 'mail' | 'whatsapp';

  @Input()
  mode: CtaCardMode;

  @Input()
  config?: CtaCardContent;

  constructor() { }

  ngOnInit(): void {
    if (!this.mode) {
      this.mode = 'regular';
    }
    if (this.preset && !this.config) {
      switch (this.preset) {
        case 'phone': {
          this.config = {
            title: 'Telefon',
            text: '0159 0646 7169',
            link: {
              href: 'tel:+4915906467169',
              text: 'Jetzt anrufen'
            },
            icon: 'phone'
          };
          break;
        }
        case 'mail': {
          this.config = {
            title: 'E-Mail',
            text: 'info@immobilien-bott.de',
            link: {
              href: 'mailto:info@immobilien-bott.de',
              text: 'Jetzt Mail schreiben'
            },
            icon: 'mail'
          };
          break;
        }
        case 'whatsapp': {
          this.config = {
            title: 'Whatsapp',
            text: '0159 0646 7169',
            link: {
              href: 'https://wa.me/4915906467169',
              text: 'Bei Whatsapp schreiben'
            },
            icon: 'whatsapp'
          };
          break;
        }
      }
    }
  }

}
