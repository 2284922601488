import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactOptionsComponent } from './components/contact-options/contact-options.component';
import { CtaCardComponent } from './components/cta-card/cta-card.component';
import { HeaderComponent } from './components/header/header.component';
import { HighLevelProcessComponent } from './components/high-level-process/high-level-process.component';
import { IconHeadingTextComponent } from './components/icon-heading-text/icon-heading-text.component';
import { ImageAndTextBoxComponent } from './components/image-and-text-box/image-and-text-box.component';
import { ListAndCtaComponent } from './components/list-and-cta/list-and-cta.component';
import { RotatingHeadlinesComponent } from './components/rotating-headlines/rotating-headlines.component';
import { StatementComponent } from './components/statement/statement.component';
import { TextAndCtaComponent } from './components/text-and-cta/text-and-cta.component';

@NgModule({
  declarations: [
    StatementComponent,
    ListAndCtaComponent,
    IconHeadingTextComponent,
    HeaderComponent,
    TextAndCtaComponent,
    ContactFormComponent,
    AlertComponent,
    RotatingHeadlinesComponent,
    CtaCardComponent,
    ContactOptionsComponent,
    HighLevelProcessComponent,
    ImageAndTextBoxComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    StatementComponent,
    ListAndCtaComponent,
    IconHeadingTextComponent,
    HeaderComponent,
    TextAndCtaComponent,
    ContactFormComponent,
    AlertComponent,
    RotatingHeadlinesComponent,
    CtaCardComponent,
    ContactOptionsComponent,
    HighLevelProcessComponent,
    ImageAndTextBoxComponent
  ]
})
export class SharedModule { }
