<header #header class="mb-6">
    <figure *ngFor="let img of config?.images; index as idx" [ngStyle]="{
        'background-image': 'url(' + img + ')',
        'animation-name': 'image-animation-' + config?.images?.length + '-images',
        'animation-duration': animationSeconds + 's',
        'animation-delay': ((animationSeconds / config?.images?.length) * idx) + 's'
    }"></figure>
    <div class="container">
        <div class="row justify-content-center justify-content-sm-start">
            <div class="col-11 col-sm-12">
                <h1 [innerHTML]="config.heading"></h1>
            </div>
        </div>
    </div>
    <div *ngIf="config?.cta" class="cta">
        <div class="text">
            <div class="text-border">
                <svg viewBox="0 0 290 257" preserveAspectRatio="none">
                    <path d="M 2 70 C 2 68 3 65 4 63 L 63 4 C 65 3 68 2 70 2 H 278 C 283 2 288 6 288 12 V 245 C 288 250 283 255 278 255 H 12 C 6 255 2 250 2 245 V 70"/>
                </svg>
            </div>
            <span [innerHTML]="config?.cta?.text"></span>
        </div>
        <div class="link">
            <a [href]="config?.cta?.link?.href">{{config?.cta?.link?.text}}</a>
        </div>
    </div>
</header>
