import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';


export interface HighLevelProcessConfig {
  step1: string;
  step2: string;
  step3: string;
  step4: string;
}

@Component({
  selector: 'bott-high-level-process',
  templateUrl: './high-level-process.component.html',
  styleUrls: ['./high-level-process.component.scss']
})
export class HighLevelProcessComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  config: HighLevelProcessConfig;

  private observer: IntersectionObserver;
  private shouldAnimateOnScroll = false;
  private scrollSub: Subscription;

  @ViewChild('step4')
  step4: ElementRef<HTMLDivElement>;

  @ViewChild('step4Shape1')
  step4Shape1: ElementRef<SVGElement>;

  @ViewChild('step4Shape2')
  step4shape2: ElementRef<SVGElement>;

  constructor() { }

  ngOnInit() {
    this.observer = new IntersectionObserver(this.intersectionObserverCallback.bind(this), {
      threshold: 0.1
    });
  }

  ngAfterViewInit() {
    this.observer.observe(this.step4.nativeElement);
    this.scrollSub = fromEvent(window, 'scroll').pipe(
      filter(_ => this.shouldAnimateOnScroll),
      tap(_ => this.onScroll())
    ).subscribe();
  }

  ngOnDestroy() {
    this.scrollSub.unsubscribe();
    this.observer.disconnect();
  }

  private onScroll() {
    window.requestAnimationFrame(() => {
      const factor = window.scrollY / window.innerHeight;
      const base = 16;
      this.step4Shape1.nativeElement.style.top = ((base * factor) - 75) + '%';
      this.step4shape2.nativeElement.style.bottom = -((1.4 * base * factor) - 95) + '%';
      this.step4shape2.nativeElement.style.left = ((0.3 * base * factor) - 18) + '%';
    });
  }

  private intersectionObserverCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    this.shouldAnimateOnScroll = entries[0].isIntersecting;
  }

}
