import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { NavGroupLink } from '../components/navbar/navbar.component';

@Pipe({
  name: 'orderNavGroups'
})
export class OrderNavGroupsPipe implements PipeTransform {

  transform(groups: KeyValue<string, NavGroupLink>[]): KeyValue<string, NavGroupLink>[] {
    return groups.sort((a, b) => {
      const idxA = a.value.extendedNavIndex;
      const idxB = b.value.extendedNavIndex;
      return idxA - idxB;
    });
  }

}
