import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/views/home/home.component';
import { FooterComponent } from './navigation/components/footer/footer.component';
import { NavbarComponent } from './navigation/components/navbar/navbar.component';
import { NotFoundPageComponent } from './not-found/views/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    outlet: 'footer',
    component: FooterComponent
  },
  {
    path: '',
    outlet: 'navbar',
    component: NavbarComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'leistungen',
    loadChildren: () => import('./leistungen/leistungen.module').then(m => m.LeistungenModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'wissen',
    loadChildren: () => import('./wissen/wissen.module').then(m => m.WissenModule)
  },
  {
    path: 'ueber-uns',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then(m => m.ImpressumModule)
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./datenschutz/datenschutz.module').then(m => m.DatenschutzModule)
  },
  {
    path: 'immobilienmakler',
    loadChildren: () => import('./standorte/standorte.module').then(m => m.StandorteModule)
  },
  {
    path: 'widerrufsbelehrung',
    loadChildren: () => import('./revocation/revocation.module').then(m => m.RevocationModule)
  },
  {
    path: 'expose',
    loadChildren: () => import('./expose/expose.module').then(m => m.ExposeModule)
  },
  {
    path: 'wertermittlung',
    loadChildren: () => import('./wertermittlung/wertermittlung.module').then(m => m.WertermittlungModule)
  },
  {
    path: '404',
    component: NotFoundPageComponent
  },
  {
    path: '**',
    component: NotFoundPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100],
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
