import { Component, OnInit, Input } from '@angular/core';
import { CtaCardMode } from '../cta-card/cta-card.component';

@Component({
  selector: 'bott-contact-options',
  templateUrl: './contact-options.component.html',
  styleUrls: ['./contact-options.component.scss']
})
export class ContactOptionsComponent implements OnInit {

  readonly presets = [
    'phone',
    'mail',
    'whatsapp'
  ];

  @Input()
  mode?: CtaCardMode;

  @Input()
  text = 'So erreichst du uns.';

  constructor() { }

  ngOnInit(): void {
  }

}
