<bott-statement *ngIf="mode !== 'small'">
  {{text}}
</bott-statement>

<div class="container">
    <div class="row justify-content-center" [class.pb-6]="mode !== 'small'">
        <div class="col-11 col-sm-12 col-md-4 mb-4 mb-md-0" *ngFor="let preset of presets">
            <bott-cta-card [containerClass]="'h-100'" [preset]="preset" [mode]="mode"></bott-cta-card>
        </div>
    </div>
</div>
