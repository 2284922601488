<footer class="py-5">
    <div class="container">
        <div class="row justify-content-center justify-content-sm-start">
            <div class="col-11 col-sm-12 col-md-6 col-lg-3 mb-4">
                <img class="mb-3" src="/assets/logo/logo_text_long.svg" alt="">
                <address class="mb-1">
                    Bott Immobilien<br>
                    Rotdornweg 3<br>
                    34587 Felsberg
                </address>
                <a class="unstyled maps-link" href="https://goo.gl/maps/QrFCGnX7LAc8nFp9A" target="_blank" rel="nofollow noopener">Auf Google Maps ansehen</a>
            </div>
            <div class="col-11 col-sm-12 col-md-6 col-lg-3 mb-4">
                <img class="mb-3 d-none d-md-block d-lg-none" src="/assets/logo/logo_text_long.svg" alt="" style="visibility: hidden; opacity: 0; user-select: none;" draggable="false">
                <h2>Kontakt</h2>
                <a class="unstyled" href="tel:+4956628239962">
                    <span style="font-weight: 600;">Tel:</span>
                    +49 5662 8239 962
                </a><br>
                <a class="unstyled" href="tel:+4915906467169">
                    <span style="font-weight: 600;">Mobil:</span>
                    +49 1590 6467 169
                </a><br>
                <a class="unstyled" href="mailto:info@immobilien-bott.de">
                    <span class="d-lg-none d-xl-inline" style="font-weight: 600;">Mail:</span>
                    info@immobilien-bott.de
                </a><br>
            </div>
            <div class="col-11 col-sm-12 col-md-6 col-lg-3 mb-4">
                <h2>Leistungen</h2>
                <a class="unstyled" routerLink="leistungen/verkaufen">Immobilie verkaufen</a><br>
                <a class="unstyled" routerLink="/wertermittlung">Wertermittlung</a><br>
                <a class="unstyled" routerLink="leistungen/homestaging">Home Staging</a><br>
            </div>
            <div class="col-11 col-sm-12 col-md-6 col-lg-3 mb-4 mb-lg-5">
                <h2>Wissen</h2>
                <a class="unstyled" routerLink="wissen/wertermittlung">Wertermittlung</a><br>
                <a class="unstyled" routerLink="wissen/expose-erstellung">Exposé-Erstellung</a><br>
                <a class="unstyled" routerLink="wissen/vermarktung">Vermarktung</a><br>
                <a class="unstyled" routerLink="wissen/dokumente">Dokumente</a><br>
                <a class="unstyled" routerLink="wissen/interessenten-analyse">Interessenten-Analyse</a><br>
                <a class="unstyled" routerLink="wissen/besichtigung">Besichtigungen</a><br>
            </div>
            <div class="col-11 col-sm-12 d-md-none mb-4">
                <h2>Rechtliches</h2>
                <a class="unstyled mb-4 mr-4" routerLink="impressum">Impressum</a><br>
                <a class="unstyled" routerLink="datenschutz">Datenschutz</a>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-11 col-sm-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-11 col-sm-12 col-md-6">
                <a class="unstyled exportarts" href="https://www.exportarts.io" target="_blank" rel="noopener nofollow">
                    Made with
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
                        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                    </svg>
                    by Exportarts
                </a>
            </div>
            <div class="d-none d-md-block col-md-6 text-right">
                <a class="unstyled mb-4 mr-4" routerLink="impressum">Impressum</a>
                <a class="unstyled" routerLink="datenschutz">Datenschutz</a>
            </div>
        </div>
    </div>
</footer>
