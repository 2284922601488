import { Component, OnInit, Input } from '@angular/core';

export interface HomeHeaderConfig {
  images: string[];
  heading: string;
  cta?: {
    text: string;
    link: {
      text: string;
      href: string;
    }
  };
}

@Component({
  selector: 'bott-home-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HomeHeaderComponent implements OnInit {

  readonly animationSeconds = 20;

  @Input()
  config: HomeHeaderConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
