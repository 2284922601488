<div class="container pt-4 pb-6" *ngIf="entries?.length > 0">
    <div class="row justify-content-center justify-content-sm-start">
        <ng-container *ngFor="let entry of entries; last as isLast">
            <div class="col-9 col-sm-10 py-3">
                <h2 class="m-0" (click)="toggle(entry)">{{entry.question}}</h2>
            </div>
            <div class="col-2 col-sm-1 ml-sm-3 ml-md-4 ml-lg-0 offset-lg-1 my-auto">
                <span role="button" (click)="toggle(entry)">
                    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <path [class.show-path]="!(opened | async)[entry.question]" d="M38 26H26V38H22V26H10V22H22V10H26V22H38V26Z"/>
                        <path [class.show-path]="(opened | async)[entry.question]" d="M38 26H26H24.5H23.5H22H10V22H22H23H24.5H26H38V26Z"/>
                    </svg>
                </span>
            </div>
            <div class="col-11" [class.d-none]="!(opened | async)[entry.question]" [@questionIn]="(opened | async)[entry.question] ? 'in' : 'out'">
                <p *ngFor="let p of entry.shortAnswer" [innerHTML]="p | answerParser : entry"></p>
            </div>
            <div class="col-11 col-sm-12" *ngIf="!isLast">
                <hr>
            </div>
        </ng-container>
    </div>
</div>
