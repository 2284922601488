<div class="container mb-5 py-5">

    <div class="row justify-content-center justify-content-sm-start mb-7 step-3">
        <div class="col-11 col-sm-12 col-lg-8 heading-row">
            <div>
                <div>1</div>
            </div>
            <div>
                <h3>Wertermittlung</h3>
                <p>
                    <span [innerHtml]="config?.step1"></span>
                    <a routerLink="/leistungen/verkaufen">Mehr erfahren.</a>
                </p>
            </div>
        </div>
        <div class="col-11 col-sm-12 content-row">
            <svg width="386" height="326" viewBox="0 0 386 326" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M384 161.589L384 2L82.1132 1.99999L82.1132 161.589L384 161.589ZM386 2L386 0L384 -8.74228e-08L82.1132 -1.32833e-05L81.934 -1.32911e-05L80.1132 -1.33707e-05L79.934 -1.33786e-05L2 -1.67852e-05L0 -1.68726e-05L-8.74228e-08 1.99998L-1.41425e-05 323.542L-1.42299e-05 325.542L1.99999 325.542L79.9339 325.542L81.9339 325.542L82.1133 325.542L238.34 325.542L238.519 325.542L238.519 325.543L240.519 325.543L384 325.543L386 325.543L386 323.543L386 213.03L386 212.666L386 211.03L386 210.666L386 163.953L386 163.589L386 161.953L386 161.589L386 2ZM238.34 323.542L238.34 163.953L82.1133 163.953L82.1133 323.542L238.34 323.542ZM384 213.03L384 323.543L240.519 323.543L240.519 213.03L384 213.03ZM384 210.666L384 163.953L240.519 163.953L240.519 210.666L384 210.666ZM79.934 1.99999L79.9339 323.542L1.99999 323.542L2 1.99998L79.934 1.99999Z" fill="#FFC107" fill-opacity="0.25"/>
            </svg>                
            <img src="/assets/images/home/process/wertermittlung.jpg" alt="">
            <div>
                <span>Wertermittlung<br>deiner Immobilie</span>
                <div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Marktberichte analysieren</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Aktuelle und Historische <br>Angebote vergleichen</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Wohnmarktanalyse erstellen</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Wertermittlungsverfahren anwenden</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Detaillierte Berechnung</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center justify-content-sm-start mb-7 step-2">
        <div class="col-11 col-sm-12 col-lg-8 heading-row">
            <div>
                <div>2</div>
            </div>
            <div>
                <h3>Exposé-Erstellung</h3>
                <p>
                    <span [innerHtml]="config?.step2"></span>
                    <a routerLink="/leistungen/verkaufen">Mehr erfahren.</a>
                </p>
            </div>
        </div>
        <div class="col-11 col-sm-12 content-row">
            <img src="/assets/images/home/process/step-2-1.jpg" alt="">
            <img src="/assets/images/home/process/step-2-2.jpg" alt="">
        </div>
    </div>

    <div class="row justify-content-center justify-content-sm-start mb-4 mb-lg-7 step-3">
        <div class="col-11 col-sm-12 col-lg-8 heading-row">
            <div>
                <div>3</div>
            </div>
            <div>
                <h3>Vermarktung</h3>
                <p>
                    <span [innerHtml]="config?.step3"></span>
                    <a routerLink="/leistungen/verkaufen">Mehr erfahren.</a>
                </p>
            </div>
        </div>
        <div class="col-11 col-sm-12 content-row">
            <svg width="386" height="326" viewBox="0 0 386 326" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M384 161.589L384 2L82.1132 1.99999L82.1132 161.589L384 161.589ZM386 2L386 0L384 -8.74228e-08L82.1132 -1.32833e-05L81.934 -1.32911e-05L80.1132 -1.33707e-05L79.934 -1.33786e-05L2 -1.67852e-05L0 -1.68726e-05L-8.74228e-08 1.99998L-1.41425e-05 323.542L-1.42299e-05 325.542L1.99999 325.542L79.9339 325.542L81.9339 325.542L82.1133 325.542L238.34 325.542L238.519 325.542L238.519 325.543L240.519 325.543L384 325.543L386 325.543L386 323.543L386 213.03L386 212.666L386 211.03L386 210.666L386 163.953L386 163.589L386 161.953L386 161.589L386 2ZM238.34 323.542L238.34 163.953L82.1133 163.953L82.1133 323.542L238.34 323.542ZM384 213.03L384 323.543L240.519 323.543L240.519 213.03L384 213.03ZM384 210.666L384 163.953L240.519 163.953L240.519 210.666L384 210.666ZM79.934 1.99999L79.9339 323.542L1.99999 323.542L2 1.99998L79.934 1.99999Z" fill="#FFC107" fill-opacity="0.25"/>
            </svg>                
            <img src="/assets/images/home/process/besichtigung.jpg" alt="">
            <div>
                <span>Vermarktung<br>deiner Immobilie</span>
                <div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Vermarktungsstrategie</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Veröffentlichen der Anzeigen</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Interessentenanalyse</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Besichtigungen arrangieren</label>
                    </div>
                    <div>
                        <input type="checkbox" checked>
                        <label>Verkaufsvorbereitung</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center justify-content-sm-start mb-5 mb-md-7 step-4" #step4>
        <div class="col-11 col-sm-12 col-lg-8 heading-row">
            <div>
                <div>4</div>
            </div>
            <div>
                <h3>Verkauf</h3>
                <p>
                    <span [innerHtml]="config?.step4"></span>
                    <a routerLink="/leistungen/verkaufen">Mehr erfahren.</a>
                </p>
            </div>
        </div>
        <div class="col-11 col-sm-12 content-row">
            <svg #step4Shape1 width="180" height="199" viewBox="0 0 233 199" fill="none" xmlns="http://www.w3.org/2000/svg" class="d-none d-md-block">
                <path d="M130.539 86.1269C134.831 63.5338 124.839 39.7959 103.944 27.7323C77.4458 12.4333 43.3774 21.7083 27.9554 48.42C12.5334 75.1316 21.5353 109.273 48.0339 124.572C68.9286 136.636 94.482 133.42 111.903 118.407L146.727 138.513L128.09 170.793L160.113 189.281L178.75 157.001L194.761 166.245L213.398 133.965L130.539 86.1269ZM66.6708 92.2923C57.8646 87.208 54.8528 75.7852 59.9779 66.9082C65.1031 58.0312 76.5014 54.9281 85.3076 60.0123C94.1138 65.0966 97.1256 76.5194 92.0005 85.3964C86.8753 94.2734 75.477 97.3766 66.6708 92.2923Z" fill="#FFC107" fill-opacity="0.12"/>
            </svg>
            <svg #step4Shape2 width="170" height="235" viewBox="0 0 205 235" fill="none" xmlns="http://www.w3.org/2000/svg" class="d-none d-md-block">
                <path d="M145.476 68.4202L136.571 71.3853L130.626 53.5309C122.422 28.8918 95.8165 15.5366 71.2391 23.7201C46.6616 31.9036 33.373 58.5422 41.577 83.1813L47.522 101.036L38.6171 104.001C28.8217 107.262 23.4825 117.965 26.7523 127.785L56.477 217.057C59.7467 226.877 70.4363 232.243 80.2317 228.982L187.09 193.401C196.886 190.14 202.225 179.436 198.955 169.617L169.23 80.3446C165.961 70.5246 155.271 65.1587 145.476 68.4202ZM118.799 166.555C109.003 169.817 98.3136 164.451 95.0439 154.631C91.7742 144.811 97.1134 134.108 106.909 130.847C116.704 127.585 127.394 132.951 130.663 142.771C133.933 152.591 128.594 163.294 118.799 166.555ZM119.652 77.0189L64.4412 95.4021L58.4963 77.5477C53.4134 62.2822 61.6594 45.752 76.8868 40.6818C92.1141 35.6116 108.624 43.899 113.707 59.1645L119.652 77.0189Z" fill="#FFC107" fill-opacity="0.12"/>
            </svg>
            <img src="/assets/images/home/process/verkauf.jpg" alt="">
        </div>
    </div>

    <div class="row justify-content-center justify-content-sm-start">
        <div class="col-11 col-sm-12 text-center mb-4">
            <strong>Verkaufe deine Immobilie mit uns!</strong>
            <p>Im persönlichen Gespräch besprechen wir gerne Ihre individuellen Wünsche.</p>
        </div>
        <div class="col-11 col-sm-12 text-center">
            <a class="bott-button inverted large" routerLink="/kontakt">Kontaktieren Sie uns</a>
        </div>
    </div>

</div>
