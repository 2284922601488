import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FaqModule } from '../faq/faq.module';
import { SharedModule } from '../shared/shared.module';
import { HomeHeaderComponent } from './components/header/header.component';
import { HomeComponent } from './views/home/home.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeHeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FaqModule,
    RouterModule
  ]
})
export class HomeModule { }
