import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuestionsComponent } from './components/questions/questions.component';
import { AnswerParserPipe } from './pipes/answer-parser.pipe';

@NgModule({
  declarations: [
    QuestionsComponent,
    AnswerParserPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    QuestionsComponent
  ]
})
export class FaqModule { }
