import { createClient, getRepositoryEndpoint } from '@prismicio/client';
import { Environment } from './model';

export const environment: Environment = {
  production: true,
  middleware: {
    url: 'https://bott-immobilien-middleware-dev.dev.paas.exportarts.io'
  },
  sentry: {
    dsn: 'https://c1339180bc5847d7aa22601ec13194fa@o245955.ingest.sentry.io/5224368'
  },
  prismic: {
    client: createClient(getRepositoryEndpoint('bott-immobilien'))
  }
};
