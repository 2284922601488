<nav class="bg-white sticky-top" #nav>
    <div class="container">
        <div class="row justify-content-center justify-content-sm-start">
            <div class="col-11 col-sm-12 d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-between align-items-center">
                    <a class="unstyled" routerLink="/" style="padding: 0 !important">
                        <img src="/assets/logo/logo_text_long.svg" alt="" class="mr-lg-5">
                    </a>
                    <a routerLink="/leistungen" class="unstyled d-none d-lg-inline mr-lg-5" #detailNavTriggerLink>Leistungen</a>
                    <a routerLink="/leistungen/verkaufen" class="unstyled d-none d-lg-inline mr-lg-5" #detailNavTriggerLink>Verkaufen</a>
                    <a routerLink="/ueber-uns" class="unstyled d-none d-lg-inline mr-lg-5" #detailNavTriggerLink>Über uns</a>
                    <a routerLink="/wertermittlung" class="unstyled d-none d-lg-inline" #detailNavTriggerLink>Wertermittlung</a>
                </div>
                <a routerLink="/kontakt" class="bott-button d-none d-md-inline">Jetzt anfragen</a>
                <span role="button" class="d-lg-none" (click)="toggle()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                    </svg>
                </span>
            </div>
        </div>
    </div>
    <div class="detail-nav" *ngFor="let group of navGroupLinks | keyvalue" [class.d-none]="!group?.value?.children" [attr.nav-link-group]="group?.key">
        <a *ngFor="let link of group?.value.children; last as isLast" class="unstyled" [class.mb-2]="!isLast" [routerLink]="link?.routerLink" [fragment]="link?.fragment" (click)="closeDetailNav(group?.key)">{{link?.name}}</a>
    </div>
    <div class="extra-nav-mobile d-md-none" #extraNavMobile>
        <a class="unstyled" routerLink="/kontakt">
            Jetzt
            <span>
              unverbindlich
            </span>
            beraten lassen
        </a>
    </div>
</nav>

<div class="extended-nav" [class.d-none]="!expanded">
    <div class="container d-flex">
        <div *ngFor="let col of extendedNavLinkGroups; first as isFirst; last as isLast" class="col-6" [class.pl-0]="isFirst" [class.pr-0]="isLast">
            <div *ngFor="let group of navGroupLinks | keyvalue | orderNavGroups | slice : col[0] : col[1]" class="d-flex flex-column link-group">
                <a [routerLink]="group.key" class="unstyled" (click)="toggle()">{{group?.value?.name}}</a>
                <a *ngFor="let link of group?.value.children" class="unstyled" [routerLink]="link?.routerLink" [fragment]="link?.fragment" (click)="toggle()">{{link?.name}}</a>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column">
        <a routerLink="/kontakt" class="bott-button d-lg-none mt-4 mx-auto" (click)="toggle()">
            Kontakt
        </a>
        <a routerLink="/wertermittlung" class="bott-button d-lg-none mt-4 mx-auto" (click)="toggle()">
            Wertermittlung
        </a>
    </div>
</div>
