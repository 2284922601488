import { Component, OnInit, Input } from '@angular/core';

export interface ImageAndTextBoxConfig {
  image: {
    url: string;
    alt?: string;
  }
}

@Component({
  selector: 'bott-image-and-text-box',
  templateUrl: './image-and-text-box.component.html',
  styleUrls: ['./image-and-text-box.component.scss']
})
export class ImageAndTextBoxComponent implements OnInit {

  @Input()
  config: ImageAndTextBoxConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
