import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FaqEntry } from '../../models/faq-entry.model';

@Component({
  selector: 'bott-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  animations: [
    trigger('questionIn', [
      state('in', style({
        opacity: 1
      })),
      state('out', style({
        opacity: 0
      })),
      transition('out => in', [
        animate(500)
      ])
    ]),
  ],
})
export class QuestionsComponent implements OnInit {

  @Input()
  entries: FaqEntry[];

  private readonly _opened = new BehaviorSubject<{
    [question: string]: boolean
  }>({});
  readonly opened = this._opened.asObservable();

  constructor() { }

  ngOnInit(): void {
  }

  toggle(entry: FaqEntry) {
    const question = entry.question;
    const status = this._opened.value[question];
    if (typeof status === 'boolean') {
      this._opened.next({
        ...this._opened.value,
        [question]: !status
      });
    } else {
      this._opened.next({
        ...this._opened.value,
        [question]: true
      });
    }
  }

}
