import { AfterViewInit, Component, ElementRef, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';

export interface NavGroupLink {
  name: string;
  extendedNavIndex: number;
  children?: {
    name: string;
    routerLink: string;
    fragment?: string;
  }[];
}

export interface NavGroupLinks {
  [key: string] : NavGroupLink
}

@Component({
  selector: 'bott-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {
  readonly navGroupLinks: NavGroupLinks = {
    '/': {
      name: 'Home',
      extendedNavIndex: 0
    },
    '/leistungen': {
      name: 'Leistungen',
      extendedNavIndex: 1,
      children: [
        {
          name: 'Verkaufen',
          routerLink: '/leistungen/verkaufen'
        },
        {
          name: 'Wertermittlung',
          routerLink: '/wertermittlung'
        },
        {
          name: 'Home Staging',
          routerLink: '/leistungen/homestaging'
        }
      ]
    },
    '/leistungen/verkaufen': {
      name: 'Verkaufen',
      extendedNavIndex: 3,
      children: [
        {
          name: 'Wertermittlung',
          routerLink: '/wissen/wertermittlung'
        },
        {
          name: 'Exposé',
          routerLink: '/wissen/expose-erstellung'
        },
        {
          name: 'Vermarktung',
          routerLink: '/wissen/vermarktung'
        },
        {
          name: 'Dokumente',
          routerLink: '/wissen/dokumente'
        },
        {
          name: 'Interessenten',
          routerLink: '/wissen/interessenten-analyse'
        },
        {
          name: 'Besichtigung',
          routerLink: '/wissen/besichtigung'
        }
      ]
    },
    '/ueber-uns': {
      name: 'Über uns',
      extendedNavIndex: 2
    }
  };
  readonly extendedNavLinkGroups = [
    [0, Math.ceil(Object.keys(this.navGroupLinks).length / 2)],
    [Math.ceil(Object.keys(this.navGroupLinks).length / 2), Object.keys(this.navGroupLinks).length]
  ];

  private readonly scrollClass = 'scrolled';

  @ViewChildren('detailNavTriggerLink')
  triggerLinks: QueryList<ElementRef<HTMLAnchorElement>>;

  @ViewChild('nav')
  nav: ElementRef<HTMLDivElement>;

  @ViewChild('extraNavMobile')
  extraNavMobile: ElementRef<HTMLDivElement>;

  expanded = false;

  constructor() { }

  ngAfterViewInit() {
    // Make sure the nav has the correct classes.
    this.onScroll();
    this.activateNavTriggerLinkHoverListener();
  }

  toggle() {
    this.expanded = !this.expanded;
    const body = document.getElementsByTagName('body').item(0);
    body.classList.toggle('overflow-hidden');
  }

  @HostListener('window:resize')
  onResize() {
    if (this.expanded && window.innerWidth > 992) {
      this.expanded = false;
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    const scroll = window.scrollY;
    if (scroll >= 50 && !this.nav.nativeElement.classList.contains(this.scrollClass)) {
      this.nav.nativeElement.classList.add(this.scrollClass);
    } else if (scroll < 50) {
      this.nav.nativeElement.classList.remove(this.scrollClass);
    }
    if (scroll >= 800 && !this.extraNavMobile.nativeElement.classList.contains(this.scrollClass)) {
      this.extraNavMobile.nativeElement.classList.add(this.scrollClass);
    } else if (scroll < 800) {
      this.extraNavMobile.nativeElement.classList.remove(this.scrollClass);
    }
  }

  closeDetailNav(identifier: string) {
    this.onNavTriggerHover(identifier, 'leave', undefined, true);
  }

  private activateNavTriggerLinkHoverListener() {
    this.triggerLinks.forEach(ref => {
      const identifier = ref.nativeElement.getAttribute('routerlink');
      ref.nativeElement.addEventListener('mouseenter', _ => this.onNavTriggerHover(identifier, 'enter', ref.nativeElement.getBoundingClientRect().x || ref.nativeElement.getBoundingClientRect().left));
      ref.nativeElement.addEventListener('mouseleave', _ => this.onNavTriggerHover(identifier, 'leave'));
    });
  }

  private onNavTriggerHover(identifier: string, event: 'enter' | 'leave', left?: number, forceLeave?: boolean) {
    const navGroup = document.querySelector(`[nav-link-group="${identifier}"]`);
    if (!navGroup) {
      return;
    }

    if (event === 'enter') {
      navGroup.classList.add('shown');
      (navGroup as HTMLElement).style.left = (left - 16) + 'px';
    } else {
      navGroup.classList.remove('shown');
      if (forceLeave) {
        navGroup.classList.add('force-hide');
        (window as any).requestTimeout(() => navGroup.classList.remove('force-hide'), 100);
      }
    }
  }

}
