<div class="container mb-5 py-5">

    <div class="row justify-content-center justify-content-sm-start mb-7">
        <div class="col-11 col-sm-12 content-row position-relative text-right">
            <svg width="386" height="326" viewBox="0 0 386 326" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M384 161.589L384 2L82.1132 1.99999L82.1132 161.589L384 161.589ZM386 2L386 0L384 -8.74228e-08L82.1132 -1.32833e-05L81.934 -1.32911e-05L80.1132 -1.33707e-05L79.934 -1.33786e-05L2 -1.67852e-05L0 -1.68726e-05L-8.74228e-08 1.99998L-1.41425e-05 323.542L-1.42299e-05 325.542L1.99999 325.542L79.9339 325.542L81.9339 325.542L82.1133 325.542L238.34 325.542L238.519 325.542L238.519 325.543L240.519 325.543L384 325.543L386 325.543L386 323.543L386 213.03L386 212.666L386 211.03L386 210.666L386 163.953L386 163.589L386 161.953L386 161.589L386 2ZM238.34 323.542L238.34 163.953L82.1133 163.953L82.1133 323.542L238.34 323.542ZM384 213.03L384 323.543L240.519 323.543L240.519 213.03L384 213.03ZM384 210.666L384 163.953L240.519 163.953L240.519 210.666L384 210.666ZM79.934 1.99999L79.9339 323.542L1.99999 323.542L2 1.99998L79.934 1.99999Z" fill="#FFC107" fill-opacity="0.25"/>
            </svg>                
            <img [src]="config.image.url" [attr.alt]="config.image.alt">
            <div>
                <span class="text-left">
                    <ng-content></ng-content>
                </span>
                <ng-content select="span.caption"></ng-content>
            </div>
        </div>
    </div>

</div>
