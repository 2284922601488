import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FaqEntry } from '../models/faq-entry.model';

@Pipe({
  name: 'answerParser'
})
export class AnswerParserPipe implements PipeTransform {

  constructor(
    private readonly sanitizer: DomSanitizer
  ) {}

  transform(raw: string, entry: FaqEntry): SafeHtml {
    let html = raw;

    if (entry.links) {
      for (const link of entry.links) {
        html = html.replace(link.token, `<a class="color-yellow" href="${link.href}">${link.text}</a>`);
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
