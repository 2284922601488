import { Component, OnInit, Input } from '@angular/core';

export interface IconHeadingTextConfig {
  items: {
    icon: string;
    heading: string;
    text: string;
    /**
     * This will be initially hidden on small screens and can be shown by the user.
     */
    readMoreText?: string;
  }[];
  mode?: 'small' | 'regular';
  columns?: 3 | 4;
  containerClass?: string;
  columnsOnMobile?: 1 | 2;
}

@Component({
  selector: 'bott-icon-heading-text',
  templateUrl: './icon-heading-text.component.html',
  styleUrls: ['./icon-heading-text.component.scss']
})
export class IconHeadingTextComponent implements OnInit {

  @Input()
  config: IconHeadingTextConfig;

  showMoreIndices: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.config.mode = this.config.mode || 'regular';
    this.config.containerClass = this.config.containerClass || 'py-5';
    this.config.columns = this.config.columns || 3;
  }

}
